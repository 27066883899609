<template>
  <div class="elv-project-components-check-point-container">
    <span v-if="props.isShowLine" class="line"></span>
    <div class="elv-project-components-check-point-select-container">
      <span class="elv-project-components-check-point-label">{{ t('common.checkPoint') }}:</span>
      <el-tooltip
        placement="bottom"
        :disabled="isDisabledSelectTip"
        popper-class="elv-project-components-check-point-help-tip"
      >
        <el-select
          v-model="currentCheckPoint"
          class="elv-project-components-check-point-select"
          :class="{ 'elv-project-components-check-point-single-text': !props.isSelect }"
          popper-class="elv-project-components-check-point-select-popover"
          :disabled="isDisabled"
          @change="onChangeCheckPoint"
        >
          <el-option v-for="item in checkPointOptions" :key="item.value" :value="item.value">
            <template #default>
              <span>{{ `${item.label} ${item.timezone}` }}</span>
            </template>
          </el-option>
        </el-select>
        <template #content>
          {{ t('message.changeCheckPointSelectMessage') }}
        </template>
      </el-tooltip>
    </div>
    <el-tooltip
      v-if="!props.isSelect"
      placement="top"
      :disabled="props.isSelect"
      popper-class="elv-project-components-check-point-help-tip"
    >
      <SvgIcon v-if="!props.isSelect" name="help" :width="12" :height="12" :fill="'#AAAFB6'" />
      <template #content>
        {{ t('message.changeCheckPointHelpMessage') }}
      </template>
    </el-tooltip>
  </div>
  <ElvMessageBox
    ref="checkPointMessageBoxRef"
    class="elv-project-components-check-point-message-box"
    :confirmButtonText="t('button.confirm')"
    :cancelButtonText="t('button.cancel')"
    :title="t('title.changeCheckPoint')"
    :loading="changePointLoading"
    @onCancelEvent="onCancelChangePoint"
    @onConfirmEvent="onConfirmChangePoint"
  >
    <template #content>
      <div class="elv-project-components-check-point-message-box-content">
        <span>{{ t('message.changeCheckPointTipTitleMessage') }}:</span>
        <span><span class="point"></span>{{ t('message.changeCheckPointTipMessageOne') }}</span>
        <span><span class="point"></span>{{ t('message.changeCheckPointTipMessageTwo') }}</span>
        <span v-if="locale === 'en'"
          ><span class="point"></span>{{ t('message.changeCheckPointTipMessageThree') }}</span
        >
      </div>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import timezoneList from '@/config/timezone'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  isSelect: {
    type: Boolean,
    default: false
  },
  isShowLine: {
    type: Boolean,
    default: true
  }
})

const { t, locale } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const oldCheckPoint = ref('')
const currentCheckPoint = ref('')
const changePointLoading = ref(false)

const checkPointMessageBoxRef = useTemplateRef('checkPointMessageBoxRef')

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const checkPointOptions = computed(() => {
  const list = Array.from({ length: 24 }, (_, i) => {
    const hour = String(i).padStart(2, '0')
    return `${hour}:00:00`
  })
  const areaTimezone = find(timezoneList, { area: entityStore.entityDetail?.timezone ?? 'UTC' })?.timezone
  return list.map((item) => ({ value: item, label: item, timezone: areaTimezone }))
})

const isDisabled = computed(() => {
  return !props.isSelect || entityStore.entityDetail?.progress.changeCheckpointStatus === 'DOING'
})

const isDisabledSelectTip = computed(() => {
  return !props.isSelect || (props.isSelect && entityStore.entityDetail?.progress.changeCheckpointStatus !== 'DOING')
})

/**
 * 取消更新日切时间点
 */
const onCancelChangePoint = () => {
  currentCheckPoint.value = oldCheckPoint.value
  checkPointMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 确认更新日切时间点
 */
const onConfirmChangePoint = async () => {
  try {
    changePointLoading.value = true
    await ProjectApi.setCheckpoint(entityId.value, { checkpoint: currentCheckPoint.value })
    checkPointMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('common.successfully'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    changePointLoading.value = false
  }
}

/**
 * 更新日切时间点
 */
const onChangeCheckPoint = () => {
  if (currentCheckPoint.value !== oldCheckPoint.value) {
    checkPointMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

const initData = () => {
  currentCheckPoint.value = entityStore.entityDetail.checkpoint
  oldCheckPoint.value = currentCheckPoint.value
}

onMounted(initData)
</script>

<style lang="scss" scoped>
.elv-project-components-check-point-container {
  display: flex;
  align-items: center;

  .line {
    height: 14px;
    border-radius: 4px;
    width: 1px;
    background-color: #dde1e6;
    margin-right: 8px;
  }

  .elv-project-components-check-point-select-container {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    .elv-project-components-check-point-label {
      font-size: 12px;
      font-weight: 400;
      color: #838d95;
      margin-right: 8px;
      white-space: nowrap;
    }

    :deep(.elv-project-components-check-point-select) {
      width: 80px;
      height: 15px;

      &.elv-project-components-check-point-single-text {
        width: 60px;

        .el-select__suffix {
          display: none;
        }
      }

      .el-select__wrapper {
        height: 15px;
        box-shadow: none;
        border: none;
        padding: 0px;
        background: transparent;
        min-height: 0px;

        .el-select__selection {
          .el-select__placeholder {
            line-height: 15px;
            height: 15px;

            span {
              height: 15px;
              line-height: 15px;
            }
          }

          .el-select__selected-item {
            input,
            span {
              height: 15px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  svg {
    margin-left: 4px;
    padding-top: 3px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.elv-project-components-check-point-select-popover {
  width: 180px;
  overflow: hidden;

  .el-select-dropdown__item {
    height: 40px;
    padding: 0px 20px;

    span {
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      color: #0e0f11;
    }
  }
}

.elv-project-components-check-point-message-box {
  width: 590px;

  .el-dialog__body {
    padding: 23px 25px;
    justify-content: flex-start;

    .elv-project-components-check-point-message-box-content {
      span {
        font-size: 12px;
        font-weight: 400px;
        font-family: 'Plus Jakarta Sans';
        color: #636b75;
        line-height: 18px;
        display: flex;
        align-items: flex-start;
        position: relative;

        &:first-child {
          font-weight: 700;
        }

        .point {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #636b75;
          margin: 7px 6px 0px 7px;
        }
      }
    }
  }
}

.elv-project-components-check-point-help-tip {
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  white-space: pre;

  .el-popper__arrow {
    display: block;
  }
}
</style>
